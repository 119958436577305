import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../actions";
import { WebsocketContext } from "../context/Websocket";
//import { PeerjsContext } from '../context/Peerjs'
import { PlayerPanel } from "../components/PlayerPanel";
import { Cards } from "../components/Cards";
import { Modal } from "react-bootstrap";
//import gopherOutline from '../images/gopher-outline.png'
import { ToastContainer, toast, Bounce } from "react-toastify";
import "./Game.scss";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

export const Game = () => {
  const websocket = useContext(WebsocketContext);
  //const { userVideo } = useContext(PeerjsContext)

  const user = useSelector((state) => state.userReducer);
  const game = useSelector((state) => state.gameReducer);
  //const peer = useSelector(state => state.peerReducer)
  const dispatch = useDispatch();

  const [playerRuleInput, setPlayerRuleInput] = useState("");
  const [cardDialog, setCardDialog] = useState(false);

  const handleFetchRoom = () =>
    websocket.send(
      JSON.stringify({
        action: "fetch",
        gameID: window.localStorage.getItem("gameID"),
        username: window.localStorage.getItem("username"),
      })
    );

  const handleStartGame = () =>
    websocket.send(
      JSON.stringify({
        action: "start",
        gameID: game.gameID,
        username: user.username,
      })
    );

  const handleSubmitPlayerRule = (rule) => {
    websocket.send(
      JSON.stringify({
        action: "newRule",
        gameID: game.gameID,
        username: user.username,
        rule: rule,
      })
    );
    setCardDialog(false);
    setPlayerRuleInput("");
  };

  const handleReset = () => window.location.reload();

  const handleCardToast = (player, value, rule, kingsRemaining) => {
    toast.error(
      <div className="toast-contents">
        {player} drew a {value}!
        <hr className="toast-split" />
        {rule}
      </div>
    );
    if (value === "KING") {
      toast.error(
        <div className="toast-contents">
          A King was drawn...
          <hr className="toast-split" />
          {kingsRemaining} to go!
        </div>
      );
    }
  };

  // useEffect to fetch existing room upon a refresh or reconnect
  useEffect(() => {
    if (user.connected && window.localStorage.getItem("gameID")) {
      console.log("Room Found in local storage");
      handleFetchRoom();
    }
    // eslint-disable-next-line
  }, [user.connected]);

  // useEffect to handle websocket messages
  useEffect(() => {
    websocket.addEventListener("message", (event) => {
      var msg = JSON.parse(event.data);
      switch (msg.action) {
        case "roomFetched":
          dispatch(allActions.gameActions.setGameStarted(msg.gameInfo.started));
          dispatch(allActions.gameActions.setCreator(msg.gameInfo.creator));
          dispatch(
            allActions.gameActions.setIsCreator(
              user.username === msg.gameInfo.creator
            )
          );
          dispatch(allActions.gameActions.setPlayers(msg.gameInfo.players));
          dispatch(allActions.gameActions.setTurn(msg.gameInfo.currentTurn));
          dispatch(
            allActions.gameActions.setLastPicked(msg.gameInfo.lastPicked)
          );
          dispatch(allActions.gameActions.setGameLocked(msg.gameInfo.locked));
          dispatch(
            allActions.gameActions.setCardsRemaining(
              msg.gameInfo.cardsRemaining
            )
          );
          if (msg.gameInfo.playerRules === null) {
            dispatch(allActions.gameActions.setPlayerRules([]));
          } else {
            dispatch(
              allActions.gameActions.setPlayerRules(msg.gameInfo.playerRules)
            );
          }
          break;
        case "roomLeft":
          window.localStorage.removeItem("gameID");
          dispatch(allActions.gameActions.setGameID(null));
          dispatch(allActions.gameActions.setPlayers([]));
          break;
        case "playerJoined":
          dispatch(allActions.gameActions.setGameStarted(msg.gameInfo.started));
          dispatch(allActions.gameActions.setPlayers(msg.gameInfo.players));
          dispatch(allActions.gameActions.setTurn(msg.gameInfo.currentTurn));
          break;
        case "playerLeft":
          dispatch(allActions.gameActions.setTurn(msg.gameInfo.currentTurn));
          dispatch(allActions.gameActions.setPlayers(msg.gameInfo.players));
          break;
        case "gameStarted":
          dispatch(allActions.gameActions.setGameLocked(msg.gameInfo.locked));
          dispatch(allActions.gameActions.setGameStarted(msg.gameInfo.started));
          dispatch(allActions.gameActions.setTurn(msg.gameInfo.currentTurn));
          break;
        case "turnStarted":
          var kingsDrawn = 4 - msg.gameInfo.kingsDrawn;
          dispatch(allActions.gameActions.setCardPicked(msg.card));
          dispatch(
            allActions.gameActions.setLastPicked(msg.gameInfo.lastPicked)
          );
          dispatch(allActions.gameActions.setKingsDrawn(kingsDrawn));
          handleCardToast(
            msg.card.pickedBy,
            msg.card.value,
            msg.card.rule,
            kingsDrawn
          );
          if (user.username === msg.card.pickedBy) {
            if (msg.card.value === "10") {
              // If card is a 10 then we need to add a new rule
              setCardDialog(true);
            }
          }
          break;
        case "turnEnded":
          setCardDialog(false);
          dispatch(allActions.gameActions.setCardPicked(""));
          dispatch(allActions.gameActions.setPlayers(msg.gameInfo.players));
          dispatch(allActions.gameActions.setTurn(msg.gameInfo.currentTurn));
          dispatch(
            allActions.gameActions.setLastPicked(msg.gameInfo.lastPicked)
          );
          dispatch(
            allActions.gameActions.setCardsRemaining(
              msg.gameInfo.cardsRemaining
            )
          );
          break;
        case "newPlayerRule":
          dispatch(
            allActions.gameActions.setPlayerRules(msg.gameInfo.playerRules)
          );
          break;
        case "gameLocked":
          dispatch(allActions.gameActions.setGameLocked(msg.gameInfo.locked));
          break;
        case "gameUnlocked":
          dispatch(allActions.gameActions.setGameLocked(msg.gameInfo.locked));
          break;
        case "gameOver":
          dispatch(allActions.gameActions.setGameOver(true));
          window.localStorage.removeItem("gameID");
          break;
        case "error":
          dispatch(allActions.userActions.setError(msg.message));
          break;
        default:
          break;
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="game-container">
        {/* Once a room has been created/joined then show the game lobby */}
        {game.gameID && (
          <div className="game-info">
            {/* This is a godforsaken nested ternary, get rid of it ASAP */}
            {!game.gameStarted && (
              <>
                {game.players.length > 1 ? (
                  game.isCreator ? (
                    <button
                      className="game-start"
                      role="img"
                      aria-label="start"
                      onClick={() => handleStartGame()}
                    >
                      Start
                    </button>
                  ) : (
                    <p className="game-waiting">
                      Waiting for the host to start the game...
                    </p>
                  )
                ) : (
                  <p className="game-waiting">
                    Waiting for more players to join...
                  </p>
                )}
              </>
            )}
          </div>
        )}

        {game.players.length >= 1 && (
          <>
            <div className="game-player-container">
              {game.players.map((player, i) => (
                <PlayerPanel key={i} id={i} player={player} />
              ))}
            </div>
          </>
        )}

        <ToastContainer
          position="bottom-center"
          autoClose={6000}
          limit={4}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={true}
          closeButton={false}
          rtl={false}
          pauseOnFocusLoss={false}
          pauseOnHover={false}
          transition={Bounce}
        />

        {/* Modal to display when a 10 is picked for rule input */}
        <Modal show={cardDialog} onHide={() => setCardDialog(false)}>
          <Modal.Header closeButton={false}>
            <Modal.Title>Make a rule!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {game.cardPicked.pickedBy === user.username &&
              game.cardPicked.value === "10" && (
                <>
                  <div className="modal-input-sublabel">
                    Make up a rule. For example, no swearing!
                  </div>
                  <input
                    className="modal-input"
                    type="input"
                    value={playerRuleInput}
                    maxLength={64}
                    onChange={(e) => setPlayerRuleInput(e.currentTarget.value)}
                  />
                </>
              )}
          </Modal.Body>
          <Modal.Footer>
            {game.cardPicked.value === "10" ? (
              game.cardPicked.pickedBy === user.username ? (
                <button
                  className="button-modal"
                  onClick={() => handleSubmitPlayerRule(playerRuleInput)}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="button-modal"
                  onClick={() => setCardDialog(false)}
                >
                  Close
                </button>
              )
            ) : (
              <button
                className="button-modal"
                onClick={() => setCardDialog(false)}
              >
                Close
              </button>
            )}
          </Modal.Footer>
        </Modal>

        {/* show game over dialog */}
        <Modal show={game.gameOver} onHide={() => handleReset()}>
          <Modal.Header>
            <Modal.Title id="game-over">Game Over!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            The game has finished, please reload the browser or close this
            message.
          </Modal.Body>
          <Modal.Footer>
            <button className="button-modal" onClick={() => handleReset()}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>

      {game.gameStarted && (
        <>
          <Cards />
        </>
      )}
    </>
  );
};
