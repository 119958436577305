import React from "react";
import Particles from "react-particles-js";
import "./ParticlesBackground.scss";

const ParticlesBackground = () => {
  return (
    <Particles
      id="particles-js"
      params={{
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#fff",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 1,
              color: "#ffffff",
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: true,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 6,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: true,
            speed: 8,
            direction: "top",
            random: true,
            straight: true,
            out_mode: "out",
            bounce: false,
          },
        },
        retina_detect: true,
      }}
    />
  );
};

export default ParticlesBackground;
