import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux'
import rootReducer from './reducers'
import { Provider } from 'react-redux'
import WebsocketProvider from './context/Websocket';
import PeerjsProvider from './context/Peerjs'
import App from './pages/App';

const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

ReactDOM.render(
    <Provider store={store}>
        <WebsocketProvider>
            <PeerjsProvider>
                <App />
            </PeerjsProvider>
        </WebsocketProvider>
    </Provider>,
document.getElementById('root')
);
