const initialState = {
  ID: "",
  IDs: [],
  streams: new Map()
}

const peerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PEER_ID":
      return {
        ...state,
        ID: action.payload
      }
    case "SET_PEER_IDS":
      return {
        ...state,
        IDs: action.payload
      }
    case "SET_PEER_STREAMS":
      return {
        ...state,
        streams: action.payload
      }
    default:
      return state
  }
}

export default peerReducer