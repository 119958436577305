const initialState = {
  gameID: window.localStorage.getItem("gameID") || null,
  creator: "",
  isCreator: false,
  players: [],
  turn: "",
  lastPicked: "",
  gameStarted: false,
  gameOver: false,
  gameLocked: false,
  kingsDrawn: 0,
  cardsRemaining: 0,
  cardPicked: "",
  playerRules: [],
  peers: {},
};

const gameReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_GAME_ID":
      return {
        ...state,
        gameID: action.payload,
      };
    case "SET_CREATOR":
      return {
        ...state,
        creator: action.payload,
      };
    case "SET_IS_CREATOR":
      return {
        ...state,
        isCreator: action.payload,
      };
    case "SET_PLAYERS":
      return {
        ...state,
        players: action.payload,
      };
    case "SET_GAME_STARTED":
      return {
        ...state,
        gameStarted: action.payload,
      };
    case "SET_TURN":
      return {
        ...state,
        turn: action.payload,
      };
    case "SET_LAST_PICKED":
      return {
        ...state,
        lastPicked: action.payload,
      };
    case "SET_GAME_OVER":
      return {
        ...state,
        gameOver: action.payload,
      };
    case "SET_GAME_LOCKED":
      return {
        ...state,
        gameLocked: action.payload,
      };
    case "SET_KINGS_DRAWN":
      return {
        ...state,
        kingsDrawn: action.payload,
      };
    case "SET_CARDS_REMAINING":
      return {
        ...state,
        cardsRemaining: action.payload,
      };
    case "SET_CARD_PICKED":
      return {
        ...state,
        cardPicked: action.payload,
      };
    case "SET_PLAYER_RULES":
      return {
        ...state,
        playerRules: action.payload,
      };
    case "SET_PEERS":
      return {
        ...state,
        peers: action.payload,
      };
    case "SET_GAME_LEFT":
      state = initialState;
    default:
      return state;
  }
};

export default gameReducer;
