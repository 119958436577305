const setPeerID = (peerID) => {
  return {
      type: "SET_PEER_ID",
      payload: peerID
  }
}

const setPeerIDs = (peerIDs) => {
  return {
    type: "SET_PEER_IDS",
    payload: peerIDs
  }
}

const setPeerStreams = (streams) => {
  return {
    type: "SET_PEER_STREAMS",
    payload: streams
  }
}

export default {
  setPeerID,
  setPeerIDs,
  setPeerStreams
}