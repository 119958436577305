import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../actions'
import { WebsocketContext } from '../context/Websocket'
import { Modal } from 'react-bootstrap';
import gopher from '../images/gopher.png'
import './Home.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

export const Home = () => {

    const websocket = useContext(WebsocketContext)

    const user = useSelector(state => state.userReducer)
    const game = useSelector(state => state.gameReducer)
    const dispatch = useDispatch()

    const [roomInput, setRoomInput] = useState('')
    const [joinRoomDialog, setJoinRoomDialog] = useState(false)
    const [createRoomDialog, setCreateRoomDialog] = useState(false)

    const handleCreateRoom = () => {
        websocket.send(JSON.stringify({
            action: "create",
            username: user.username,
            avatarUrl: user.avatarUrl,
        }))
        setCreateRoomDialog(false)
    }

    const handleJoinRoom = (gameID) => {
        websocket.send(JSON.stringify({
            action: "join",
            gameID: gameID,
            username: user.username,
            avatarUrl: user.avatarUrl,
            //peerID: `${gameID}${user.username}`,
        }))
        setJoinRoomDialog(false)
    }

    // useEffect to handle websocket messages
    useEffect(() => {
        websocket.addEventListener('message', (event) => {
            var msg = JSON.parse(event.data)
            switch (msg.action) {
                case "roomCreated":
                    window.localStorage.setItem('gameID', msg.gameInfo.gameID)
                    dispatch(allActions.gameActions.setGameID(msg.gameInfo.gameID))
                    dispatch(allActions.gameActions.setGameStarted(msg.gameInfo.started))
                    dispatch(allActions.gameActions.setCreator(msg.gameInfo.creator))
                    dispatch(allActions.gameActions.setIsCreator(user.username === msg.gameInfo.creator))
                    dispatch(allActions.gameActions.setPlayers(msg.gameInfo.players))
                    dispatch(allActions.gameActions.setTurn(msg.gameInfo.currentTurn))
                    break;
                case "roomJoined":
                    window.localStorage.setItem('gameID', msg.gameInfo.gameID)
                    dispatch(allActions.gameActions.setGameID(msg.gameInfo.gameID))
                    dispatch(allActions.gameActions.setGameStarted(msg.gameInfo.started))
                    dispatch(allActions.gameActions.setCreator(msg.gameInfo.creator))
                    dispatch(allActions.gameActions.setIsCreator(user.username === msg.gameInfo.creator))
                    dispatch(allActions.gameActions.setPlayers(msg.gameInfo.players))
                    dispatch(allActions.gameActions.setTurn(msg.gameInfo.currentTurn))
                    break;
                case "error":
                    switch (true) {
                        case (msg.message === 'roomNotFound'):
                            dispatch(allActions.userActions.setError(msg.message))
                            dispatch(allActions.gameActions.setGameID(null))
                            dispatch(allActions.gameActions.setPlayers([]))
                            window.localStorage.removeItem('gameID')
                            setRoomInput("")
                            break;
                        default:
                            dispatch(allActions.userActions.setError(msg.message))
                            break;
                    }
                    break;
                default:
                    break;
            }
        })
        // eslint-disable-next-line
    }, []);

    return (
        <div className="home-container">
            {/* If no room has been created/joined then show the home menu */}
            {!game.gameID && (
                <>
                    <img className="home-logo" src={gopher} alt="crown" />
                    <h1 className="home-title">RinGo'Fire</h1>
                    <div className="home-buttons">
                        <button className="home-button" id="create-button"  onClick={() => setCreateRoomDialog(true)}>Create Game</button>
                        <button className="home-button" id="join-button"  onClick={() => setJoinRoomDialog(true)}>Join Game</button>
                    </div>
                </>
            )}

            {/* modal for creating a new room */}
            <Modal show={createRoomDialog} onHide={() => setCreateRoomDialog(false)}>
                <Modal.Header>
                    <Modal.Title>Create a new game</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Would you like to create a new game?
                </Modal.Body>
                <Modal.Footer>
                    <button className="home-button-modal" onClick={() => setCreateRoomDialog(false)}>Close</button>
                    <button className="home-button-modal" onClick={() => handleCreateRoom()}>Create</button>
                </Modal.Footer>
            </Modal>

            {/* modal for entering room number to join */}
            <Modal show={joinRoomDialog} onHide={() => setJoinRoomDialog(false)}>
                <Modal.Header>
                    <Modal.Title>Join a game</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-input-sublabel">Enter the four digit room ID provided by the host</div>
                    <input
                        className="modal-input"
                        type="input"
                        value={roomInput}
                        maxLength={4}
                        onChange={(e) => setRoomInput(e.currentTarget.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="home-button-modal" onClick={() => setJoinRoomDialog(false)}>Close</button>
                    <button className="home-button-modal" onClick={() => handleJoinRoom(roomInput)}>Join</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}