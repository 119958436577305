import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../actions";
import { WebsocketContext } from "../context/Websocket";
import config from "../config";
import { PeerjsContext } from "../context/Peerjs";
import { Modal } from "react-bootstrap";
import asyncLocalStorage from "../utils/asyncLocalStorage";
import "./Navbar.scss";

// Navbar - Displays the users name, along with action buttons
export const Navbar = () => {
  const websocket = useContext(WebsocketContext);
  const { userVideo } = useContext(PeerjsContext);

  const user = useSelector((state) => state.userReducer);
  const game = useSelector((state) => state.gameReducer);
  const dispatch = useDispatch();

  const [usernameInput, setUsernameInput] = useState("");
  const [avatarDialog, setAvatarDialog] = useState(false);
  const [leaveRoomDialog, setLeaveRoomDialog] = useState(false);
  const [playerRulesDialog, setPlayerRulesDialog] = useState(false);
  const [webcamEnabled, setWebcamEnabled] = useState(false);
  const [localStream, setLocalStream] = useState(null);

  const handleChangeUsername = () => {
    window.localStorage.removeItem("username");
    dispatch(allActions.userActions.setUsername(null));
  };

  const handleSetUsername = (username) => {
    if (validateName(username)) {
      dispatch(allActions.userActions.setUsername(username));
      window.localStorage.setItem("username", username);
    }
  };

  const validateName = (username) => {
    if (username.length > 12) {
      dispatch(
        allActions.userActions.setError(
          "Username cannot be longer than 12 characters"
        )
      );
      return false;
    }
    return true;
  };

  const handleLockGame = () =>
    websocket.send(
      JSON.stringify({
        action: "lock",
        gameID: game.gameID,
      })
    );

  const handleLeaveRoom = () => {
    websocket.send(
      JSON.stringify({
        action: "leave",
        gameID: game.gameID,
        username: user.username,
      })
    );
    setLeaveRoomDialog(false);
  };

  // Reads in the file from the user, uploads to S3
  // and saves to local storage
  const handleSetAvatar = (event) => {
    const file = event.target.files[0];
    /* Save the file to localstorage so that we can re-upload whenever we join a new game */
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      window.localStorage.setItem("avatarImg", reader.result);
      handleUploadAvatar(reader.result);
    });
    reader.readAsDataURL(file);
    setAvatarDialog(false);
  };

  // Handles the upload of the file to amazon S3
  const handleUploadAvatar = (base64File) => {
    const i = base64File.indexOf("base64,");
    const buffer = Buffer.from(base64File.slice(i + 7), "base64");
    const file = new File([buffer], "", { type: "image/jpg" });
    const url = `${config.httpApiUrl}/post/media`;
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        fileType: file.type,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        var signedRequestUrl = data.presignedUrl;
        var imageUrl = data.imageUrl;
        fetch(signedRequestUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read",
          },
          body: file,
        }).then(() => {
          window.localStorage.setItem("avatarUrl", imageUrl);
          dispatch(allActions.userActions.setAvatarUrl(imageUrl));
        });
      })
      .catch((err) => console.error(err));
  };

  // Quick HEAD check on the existing S3 URL to see
  // if the object has expired or not
  const checkAvatarUrl = () => {
    let statusCode;
    fetch(user.avatarUrl, {
      method: "HEAD",
    })
      .then(() => {
        console.log("Avatar exists in cache, skipping re-upload...");
      })
      .catch(() => {
        console.log("Re-uploading avatar to S3...");
        window.localStorage.removeItem("avatarUrl");
        asyncLocalStorage
          .getItem("avatarImg")
          .then((file) => handleUploadAvatar(file));
      });
    return statusCode;
  };

  // Check if the users avatar exists in S3, if not then reupload from local storage
  useEffect(() => {
    checkAvatarUrl();
  }, []);

  const mediaConstraints = {
    audio: true,
    sampleRate: 22050,
    video: {
      width: 1280,
      height: 720,
    },
  };

  // When user starts stream
  // 1) Get their webcam stream using getUserMedia
  // 2) inform the server to update the peerID of the player
  // 3) We get a response of type "callPeers" where we call all other connected peers (see src/context/Peerjs.js)
  const toggleWebcam = async () => {
    if (webcamEnabled === false) {
      setWebcamEnabled(true);
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          mediaConstraints
        );
        userVideo.current.srcObject = stream;
        setLocalStream(stream); // Need this to be able to stop a users video/audio
        // Tell the server we have a peerID
        websocket.send(
          JSON.stringify({
            action: "startStream",
            username: user.username,
            gameID: game.gameID,
            peerID: `${game.gameID}${user.username}`, // PeerID is gameID + username
          })
        );
      } catch (err) {
        console.error(err);
      }
    } else {
      setWebcamEnabled(false);
      userVideo.current.srcObject = null;
      localStream.getTracks().forEach((track) => track.stop());
    }
  };

  return (
    <div className="nav-container">
      {/* Dont show anything unless the user has set their name */}
      {user.username ? (
        <>
          <div className="nav-greeting">
            <span className="nav-name" role="img" aria-label="wave">
              👋 {user.username}
            </span>
            <img
              src={
                user.avatarUrl
                  ? user.avatarUrl
                  : "https://thumbs.dreamstime.com/b/gray-man-avatar-design-concept-ai-supported-81256396.jpg"
              }
              height="40px"
              width="40px"
              style={{ borderRadius: "50%", marginLeft: "10px" }}
              onClick={() => setAvatarDialog(!avatarDialog)}
            />
            {game.gameID && config.featureFlags.video && (
              <span
                className="nav-toggle-video"
                style={{ marginLeft: "10px" }}
                role="img"
                aria-label="toggle-video"
                onClick={() => toggleWebcam()}
              >
                📸
              </span>
            )}
          </div>

          {game.gameID && (
            <div className="nav-action-buttons">
              {/* If the user is the creator of the game show some 'admin' buttons */}
              {game.isCreator &&
                (game.gameLocked ? (
                  <span
                    className="nav-locked"
                    role="img"
                    aria-label="locked"
                    onClick={() => handleLockGame()}
                  >
                    🔒
                  </span>
                ) : (
                  <span
                    className="nav-unlocked"
                    role="img"
                    aria-label="unlocked"
                    onClick={() => handleLockGame()}
                  >
                    🔓
                  </span>
                ))}

              {/* Display player made rules if they have been created */}
              {game.playerRules.length > 0 && (
                <span
                  className="nav-rules"
                  role="img"
                  aria-label="rules"
                  onClick={() => setPlayerRulesDialog(true)}
                >
                  📜
                </span>
              )}

              {/* Leave room button */}
              <span
                className="nav-exit"
                role="img"
                aria-label="exit"
                onClick={() => setLeaveRoomDialog(true)}
              >
                🚫
              </span>
              <span className="nav-room-id">{game.gameID}</span>
            </div>
          )}
        </>
      ) : (
        <span
          className="nav-name"
          role="img"
          aria-label="wave"
          onClick={() => handleChangeUsername()}
        >
          👋
        </span>
      )}

      {/* modal for verifying user leaving room */}
      <Modal show={leaveRoomDialog} onHide={() => setLeaveRoomDialog(false)}>
        <Modal.Header>
          <Modal.Title>Leave Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to leave?</Modal.Body>
        <Modal.Footer>
          <button
            className="button-modal"
            onClick={() => setLeaveRoomDialog(false)}
          >
            No
          </button>
          <button className="button-modal" onClick={() => handleLeaveRoom()}>
            Yes
          </button>
        </Modal.Footer>
      </Modal>

      {/* modal for displaying player rules */}
      <Modal
        show={playerRulesDialog}
        onHide={() => setPlayerRulesDialog(false)}
      >
        <Modal.Header>
          <Modal.Title>Player Rules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="nav-player-rules">
            {game.playerRules.length > 0 &&
              game.playerRules.map((playerRule, i) => (
                <p key={i}>
                  {playerRule.createdBy} - {playerRule.rule}
                </p>
              ))}
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal to change name */}
      <Modal
        show={!user.username}
        onHide={() =>
          dispatch(allActions.userActions.setUsername(usernameInput))
        }
      >
        <Modal.Header>
          <Modal.Title>Enter your username</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="modal-input"
            type="input"
            value={usernameInput}
            maxLength={12}
            onChange={(e) => setUsernameInput(e.target.value)}
          />
          <div className="modal-input-sublabel" style={{ textAlign: "right" }}>
            {usernameInput.length}/{12}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            id="button-submit"
            className="button-modal"
            onClick={() => handleSetUsername(usernameInput)}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal to change avatar */}
      <Modal show={avatarDialog} onHide={() => setAvatarDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change your Avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="modal-input"
            type="file"
            accept={[".jpg", ".png"]}
            onChange={(e) => handleSetAvatar(e)}
          />
          <div className="modal-input-sublabel" style={{ textAlign: "right" }}>
            Max file size: 256kb
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            id="button-submit"
            className="button-modal"
            onClick={() => handleSetUsername(usernameInput)}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
