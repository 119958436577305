import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../actions'
import config from '../config'
import { Navbar } from '../components/Navbar'
import FireBackground from '../components/ParticlesBackground';
import { Home } from './Home'
import { Game } from './Game'
import { Alert, Spinner } from 'react-bootstrap';
import './App.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

    const user = useSelector(state => state.userReducer)
    const game = useSelector(state => state.gameReducer)
    const dispatch = useDispatch()

    return (
        <div className="root">
            <Navbar />
            <FireBackground />
            <>
                {/* If not connected, show loading spinner overlay */}
                {!user.connected && (
                    <div className="loading-overlay">
                        <Spinner animation="border" />
                    </div>
                )}

                {/* generic error alert, takes error message from api response */}
                <Alert show={user.error} onClose={() => dispatch(allActions.userActions.setError(false))} variant="danger" dismissible>
                    <Alert.Heading>Oh snap! there was an error...</Alert.Heading>
                    <p>
                        {user.error}
                    </p>
                </Alert>

                {!user.connected && (
                    <Alert variant="danger" dismissible>
                        <Alert.Heading>Oh snap! There was an error...</Alert.Heading>
                        <p>
                            Could not connect to the game server at {config.websocketUrl}
                        </p>
                    </Alert>
                )}

                {/* If no Game ID set, show the home page */}
                {!game.gameID ? (
                    <Home />
                ) : (
                    <Game />
                )}
            </>
        </div>
    );
}

export default App;