import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import allActions from '../actions'
import Peer from "peerjs";
import { WebsocketContext } from './Websocket'
import config from '../config'

const PeerjsContext = createContext(null)

export { PeerjsContext }

export default ({ children }) => {

  const user = useSelector(state => state.userReducer)
  const game = useSelector(state => state.gameReducer)
  const peer = useSelector(state => state.peerReducer)
  const dispatch = useDispatch()

  const websocket = useContext(WebsocketContext)
  const peerjsUrl = config.peerjsHost

  /* Refs */
  const userVideo = useRef();

  var peerjs

  // useEffect for defining the actions taken by peerjs
  useEffect(() => {
    // Only connect to the peerjs server when a game is joined and when the video feature flag is enabled
    if (game.gameID != null && config.featureFlags.video === true) {

      var peerID = `${game.gameID}${user.username}`
      peerjs = new Peer(peerID, { host: peerjsUrl, port: 443, path: '/ringofire', secure: true });

      peerjs.on('open', (id) => {
        console.log(`Connected to the peerjs server with id ${id}`)
        dispatch(allActions.peerActions.setPeerID(id))
      });

      peerjs.on('disconnect', () => {
        console.log('disconnected from peerjs server')
        peerjs.reconnect()
      });

      peerjs.on('error', (error) => {
        console.error(error)
      });

      // Handle any incoming calls from other players when they join/reload
      peerjs.on('call', (call) => {
        console.log("receiving connection from %o", call)
        const callerName = call.peer
        // Answer with my local mediaStream if it's set
        if (typeof userVideo.current !== "undefined") {
          call.answer(userVideo.current.srcObject)
        } else {
          // Answer without local stream if I don't have one
          call.answer()
        }
        // THIS BIT IS NEEDED BELOW
        call.on('stream', (remoteStream) => {
          // Have to pass a new copy of the map rather than a clone to trigger re-render 
          // https://medium.com/swlh/using-es6-map-with-react-state-hooks-800b91eedd5f
          dispatch(allActions.peerActions.setPeerStreams(new Map(peer.streams.set(callerName, remoteStream))))
        });
      });

      async function callPlayer(player) {
        let call

        console.log(`Connecting to ${player.peerID}...`)
        try {
          call = await peerjs.call(`${game.gameID}${player.username}`, userVideo.current.srcObject)
          // Add the player stream once you've received an answer
          call.on("stream", (remoteStream) => {
            console.log('Got some kind of call back from the other end')
            // Have to pass a new copy of the map rather than a clone to trigger re-render 
            // https://medium.com/swlh/using-es6-map-with-react-state-hooks-800b91eedd5f
            dispatch(allActions.peerActions.setPeerStreams(new Map(peer.streams.set(player.peerID, remoteStream))))
          })
        }
        catch (err) {
          console.error(err)
          return
        }
      }

      websocket.addEventListener('message', (event) => {
        var msg = JSON.parse(event.data)
        switch (msg.action) {
          // After playerStreamStarted we get a "callPeers" response, here we handle looping through
          // the returned gameInfo and calling each peerID.
          case "callPeers":
            dispatch(allActions.gameActions.setPlayers(msg.gameInfo.players))
            msg.gameInfo.players.forEach((player) => {
              // Don't bother calling yourself
              if (player.username === user.username) {
                return
              }
              callPlayer(player)
            })
            break;
          default:
            break;
        }
      })
    }
  }, [game.gameID])

  return (
    <PeerjsContext.Provider value={{ peerjs: peerjs, userVideo: userVideo }}>
      {children}
    </PeerjsContext.Provider>
  )
}