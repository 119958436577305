import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { WebsocketContext } from "../context/Websocket";
import styled, { css, keyframes } from "styled-components";
import { Modal } from "react-bootstrap";
import config from "../config";
import "./PlayerPanel.scss";

// Glow surrounds the active players panel with a glow so all
// players know whose turn it is
const Glow = keyframes`
  0% {
    box-shadow: 0px 0px 10px 1px white;
  }
  25% {
    box-shadow: 0px 0px 10px 5px white;
  }
  50% {
    box-shadow: 0px 0px 10px 8px white;
  }
  75% {
    box-shadow: 0px 0px 10px 5px white;
  }
  100% {
    box-shadow: 0px 0px 10px 1px white;
  }
`;

// Using styled-components for PlayerPanel div so that we can
// make use of keyframes based on react state.
const StyledPlayerPanel = styled.div`
  ${(props) =>
    props.active &&
    css`
      animation: ${Glow} 0.7s linear infinite;
    `}
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 7.5px;
  background-image: linear-gradient(to top, #f46b45, #eea849);
  text-align: center;
  padding: 5px 7px 0 7px;
`;

const StyledPlayerAvatar = styled.img`
  border-radius: 9999px;
  width: 8rem;
  height: 8rem;
  float: left;
  @media only screen 
  and (min-device-width: 350px) 
  and (max-device-width: 1000px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    width: 4rem;
    height: 4rem;
  }
`;

// PlayerPanel - Displays a users name, card inventory and webcam.
export const PlayerPanel = (props) => {
  const [playerToKick, setPlayerToKick] = useState("");
  const [kickPlayerDialog, setKickPlayerDialog] = useState(false);
  const videoRef = useRef();

  const websocket = useContext(WebsocketContext);

  const user = useSelector((state) => state.userReducer);
  const game = useSelector((state) => state.gameReducer);

  // Function that checks if the user is the game creator
  const handleKickPlayerDialog = (playerToKick) => {
    if (game.creator === user.username) {
      setPlayerToKick(playerToKick);
      setKickPlayerDialog(true);
    }
  };

  // Function to initiate the player kick
  const handleKickPlayer = (username) => {
    if (game.creator === user.username) {
      websocket.send(
        JSON.stringify({
          action: "kickPlayer",
          gameID: game.gameID,
          player: username,
        })
      );
      setKickPlayerDialog(false);
    }
  };

  useEffect(() => {
    if (config.featureFlags.video) {
      videoRef.current.srcObject = props.playerWebcam;
    }
  }, [props.playerWebcam]);

  const cardInventory =
    props.player.inventory === null ? [] : props.player.inventory.slice(-8);

  return (
    <>
      <StyledPlayerPanel
        className="game-player-panel"
        active={game.turn === props.player.username}
      >
        <p>{props.player.username}</p>

        {/*         {config.featureFlags.video && (
          <video
            className="game-player-video"
            playsInline
            autoPlay
            ref={videoRef}
            poster={gopher}
          />
        )} */}

        <StyledPlayerAvatar
          src={
            props.player.avatarUrl
            ? props.player.avatarUrl
            : "https://thumbs.dreamstime.com/b/gray-man-avatar-design-concept-ai-supported-81256396.jpg"
          }
          onClick={() => handleKickPlayerDialog(props.player.username)}
        />
        <table>
          <tbody>
            <tr>
              {game.gameStarted && (
                <td className="game-player-cards">
                  {cardInventory.map((card, i) => (
                    <img
                      className="game-card"
                      key={i}
                      src={card.imageUrl}
                      alt="card"
                    ></img>
                  ))}
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </StyledPlayerPanel>

      {/* modal for kicking a player from the game */}
      <Modal show={kickPlayerDialog} onHide={() => setKickPlayerDialog(false)}>
        <Modal.Header>
          <Modal.Title>Remove Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>Would you like to kick {playerToKick}?</Modal.Body>
        <Modal.Footer>
          <button
            className="button-modal"
            onClick={() => setKickPlayerDialog(false)}
          >
            Close
          </button>
          <button
            className="button-modal"
            onClick={() => handleKickPlayer(playerToKick)}
          >
            Kick
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
