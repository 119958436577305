import userActions from './user'
import gameActions from './game'
import peerActions from './peers'

const allActions = {
    userActions,
    gameActions,
    peerActions
}

export default allActions