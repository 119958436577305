const setUsername = (user) => {
  return {
      type: "SET_USERNAME",
      payload: user
  }
}

const setConnected = (bool) => {
  return {
      type: "SET_CONNECTED",
      payload: bool
  }
}

const setError = (error) => {
  return {
    type: "SET_ERROR",
    payload: error
  }
}

const setAvatarUrl = (url) => {
  return {
    type: "SET_AVATAR_URL",
    payload: url
  }
}

export default {
  setUsername,
  setConnected,
  setError,
  setAvatarUrl,
}