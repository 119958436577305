import userReducer from './user'
import gameReducer from './game'
import peerReducer from './peers'
import {combineReducers} from 'redux'

const rootReducer = combineReducers({
    userReducer,
    gameReducer,
    peerReducer
})

export default rootReducer