import React, { createContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import allActions from '../actions'
import config from '../config'

const WebsocketContext = createContext(null)

export { WebsocketContext }

export default ({ children }) => {

  const websocketUrl = config.websocketUrl

  const websocket = new WebSocket(websocketUrl);

  const dispatch = useDispatch();

  useEffect(() => {
    websocket.onopen = () => {
      console.log("Connected to the game server")
      dispatch(allActions.userActions.setConnected(true))
    }
  
    websocket.onclose = () => {
      dispatch(allActions.userActions.setConnected(false))
      setTimeout(() => window.location.reload(), 1000)
    }
  // eslint-disable-next-line
  }, [])

  return (
    <WebsocketContext.Provider value={websocket}>
      {children}
    </WebsocketContext.Provider>
  )
}