const initialState = {
  username: window.localStorage.getItem('username'), // Use local storage by default
  peerID: "",
  connected: false,
  error: false,
  avatarUrl: window.localStorage.getItem("avatarUrl") || false,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USERNAME":
      return {
        ...state,
        username: action.payload
      }
    case "SET_PEERID":
      return {
        ...state,
        peerID: action.payload
      }
    case "SET_CONNECTED":
      return {
        ...state,
        connected: action.payload
      }
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload
      }
    case "SET_AVATAR_URL":
      return {
        ...state,
        avatarUrl: action.payload
      }
    default:
      return state
  }
}

export default userReducer