import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { WebsocketContext } from "../context/Websocket";
import styled, { css, keyframes } from "styled-components";
import ReactCardFlip from "react-card-flip";
import CardBack from "../images/CardBack.svg";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInAnimation = () =>
  css`
    ${fadeIn} 2s ease;
  `;

const CardRingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  z-index: ${(props) => (props.isTurn ? "20" : "0")};
`;

const ReactCardFlipContainer = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
`;

const StyledCard = styled.img`
    position: absolute;
    height: 125px;
    transition: 500ms ease;
    top: 45%;
    left: 50%;
    @media screen and (max-width: 700px) {
        height: 80px;
    @media screen and (max-width: 350px) {
        height: 80px;
    }
`;

const createCardRingCSS = (props) => {
  var { cardCount } = props;
  let styles = `
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        height: 100%;
        width: 100%;
        margin: 0 auto;
    `;
  var angle = 353 / cardCount;
  let cardRotation = angle;
  for (let i = 0; i < cardCount; i++) {
    cardRotation += angle;
    styles += `
        & #game-card-${i} {
            position: absolute;
            transform: translate(-50%,-50%) rotate(${cardRotation}deg) translate(${
      500 / 1.7
    }px) rotate(90deg);
            transition: All 300ms ease;
        }
        & #game-card-${i}:hover,
          #game-card-${i}:focus {
            transform: translate(-50%,-50%) rotate(${cardRotation}deg) translate(${
      500 / 1.4
    }px) rotate(90deg) scale(1.3);
            transition: All 100ms;
        }
        @media screen and (max-width: 700px) {
            & #game-card-${i} {
                transform: translate(-50%,-50%) rotate(${cardRotation}deg) translate(${
      240 / 1.7
    }px) rotate(90deg);
            }
            & #game-card-${i}:hover,
              #game-card-${i}:focus {
                transform: translate(-50%,-50%) rotate(${cardRotation}deg) translate(${
      240 / 1.4
    }px) rotate(90deg) scale(1.3);
                transition: All 100ms;
            }
        }
        `;
  }
  return css`
    animation: ${fadeInAnimation} ${styles};
  `;
};

const CardRing = styled.div`
  ${(props) => createCardRingCSS(props)}
`;

const EndTurn = styled.button`
  position: absolute;
  top: 77%;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%) !important;

  &:active {
    position: relative;
    box-shadow: none !important;
    top: 77.5%;
    transition: All 250ms ease;
  }
`;

export const Cards = () => {
  const websocket = useContext(WebsocketContext);

  const user = useSelector((state) => state.userReducer);
  const game = useSelector((state) => state.gameReducer);

  const handleEndTurn = () => {
    websocket.send(
      JSON.stringify({
        action: "endTurn",
        gameID: game.gameID,
        username: user.username,
      })
    );
  };

  const Card = (props) => {
    const handlePickCard = () => {
      websocket.send(
        JSON.stringify({
          action: "startTurn",
          gameID: game.gameID,
          username: user.username,
        })
      );
    };

    return (
      <StyledCard
        id={props.id}
        src={CardBack}
        height="250px"
        onClick={() => handlePickCard()}
      />
    );
  };

  const Cards = () => {
    var cards = [];
    for (var i = 0; i < game.cardsRemaining; i++) {
      cards.push(<Card id={`game-card-${i}`} key={i} num={i} />);
    }
    return cards;
  };

  const CardReveal = (props) => {
    const [flipped, setFlipped] = useState(false);
    const [flipSpeed, setFlipSpeed] = useState(1);

    let { card } = props;

    useEffect(() => {
      setFlipped(true);
      switch (true) {
        case game.cardsRemaining < 10:
          if (card.value === "KING") {
            setFlipSpeed(7);
            break;
          }
          setFlipSpeed(5);
          break;
        case card.value === "KING":
          setFlipSpeed(3);
          break;
        default:
          break;
      }
    }, [card]);

    return (
      <ReactCardFlipContainer>
        <ReactCardFlip
          isFlipped={flipped}
          flipSpeedBackToFront={flipSpeed}
          flipSpeedFrontToBack={flipSpeed}
          flipDirection="horizontal"
        >
          <div>
            <img
              src={CardBack}
              height="314px"
              alt="card"
              style={{ zIndex: 2 }}
            />
          </div>
          <div>
            <img
              src={props.card.imageUrl}
              height="314px"
              alt="card"
              style={{ zIndex: 10 }}
            />
          </div>
        </ReactCardFlip>
      </ReactCardFlipContainer>
    );
  };

  return (
    <CardRingContainer isTurn={game.turn === user.username}>
      {game.turn === user.username && (
        <>
          <CardRing cardCount={game.cardsRemaining}>
            <Cards />
          </CardRing>
          {/* Check to see if the lastPicked value is the same as the current player, we need this in case the user disconnects/reconnects during a turn */}
          {game.lastPicked === game.turn && (
            <EndTurn id="end-turn" onClick={() => handleEndTurn(true)}>
              End Turn
            </EndTurn>
          )}
        </>
      )}
      {game.cardPicked && <CardReveal card={game.cardPicked} />}
    </CardRingContainer>
  );
};
