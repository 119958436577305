const dev = {
  websocketUrl: "wss://kmbwxjz71c.execute-api.eu-west-1.amazonaws.com/dev",
  httpApiUrl: "https://88s9gdmcdc.execute-api.eu-west-1.amazonaws.com",
  peerjsHost: "163ccc62ad34.eu.ngrok.io",
  turnWait: 7000,
  featureFlags: {
    video: false
  }
};

const prod = {
  websocketUrl: "wss://32v8vgv9z4.execute-api.eu-west-1.amazonaws.com/prod",
  httpApiUrl: "https://l9y9ov92t8.execute-api.eu-west-1.amazonaws.com",
  peerjsHost: "peer.ringofire.edsdemo.co.uk",
  turnWait: 7000,
  featureFlags: {
    video: false
  }
};

const config = process.env.REACT_APP_STAGE === 'development' ? dev : prod;

export default config