const setGameID = (gameID) => {
  return {
    type: "SET_GAME_ID",
    payload: gameID
  }
}

const setCreator = (bool) => {
  return {
    type: "SET_CREATOR",
    payload: bool
  }
}

const setIsCreator = (bool) => {
  return {
    type: "SET_IS_CREATOR",
    payload: bool
  }
}

const setPlayers = (players) => {
  return {
    type: "SET_PLAYERS",
    payload: players
  }
}

const setGameStarted = (bool) => {
  return {
    type: "SET_GAME_STARTED",
    payload: bool
  }
}

const setTurn = (player) => {
  return {
    type: "SET_TURN",
    payload: player
  }
}

const setLastPicked = (player) => {
  return {
    type: "SET_LAST_PICKED",
    payload: player
  }
}

const setGameOver = (bool) => {
  return {
    type: "SET_GAME_OVER",
    payload: bool
  }
}

const setGameLocked = (bool) => {
  return {
    type: "SET_GAME_LOCKED",
    payload: bool
  }
}

const setKingsDrawn = (int) => {
  return {
    type: "SET_KINGS_DRAWN",
    payload: int
  }
}

const setCardsRemaining = (int) => {
  return {
    type: "SET_CARDS_REMAINING",
    payload: int
  }
}

const setCardPicked = (card) => {
  return {
    type: "SET_CARD_PICKED",
    payload: card
  }
}

const setPlayerRules = (rules) => {
  return {
    type: "SET_PLAYER_RULES",
    payload: rules
  }
}

const setGameLeft = () => {
  return {
    type: "SET_GAME_LEFT"
  }
}

export default {
  setGameID,
  setCreator,
  setIsCreator,
  setPlayers,
  setGameStarted,
  setTurn,
  setLastPicked,
  setGameOver,
  setGameLocked,
  setKingsDrawn,
  setCardsRemaining,
  setCardPicked,
  setPlayerRules,
  setGameLeft,
}